export const API_URL = {
  // API_URL: 'http://localhost:3000',
  // API_URL: 'http://api.repair.riseplus.tech',
  // socket: 'http://ws.en-queue.riseplus.tech',
  // web_url: 'http://en-queue.riseplus.tech',

  API_URL: 'https://api.fix.enit.kku.ac.th',
  socket: 'https://ws.api.fix.enit.kku.ac.th',
  web_url: 'https://fix.enit.kku.ac.th',
}
