import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common'

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { PrintJobComponent } from './component/print-job/print-job.component';

@NgModule({
  declarations: [
    AppComponent,
    PrintJobComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule,

    HttpClientModule,
    DateInputsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
