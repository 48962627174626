import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from "../../service/job.service";
import { DatePipe } from '@angular/common';
import { API_URL } from "../../service/api-url";
@Component({
  selector: 'app-print-job',
  templateUrl: './print-job.component.html',
  styleUrls: ['./print-job.component.css']
})
export class PrintJobComponent implements OnInit {
  job_id: any;
  job: any = {};
  api_url = API_URL;
  constructor(
    private route: ActivatedRoute,
    private JobService: JobService,
    public datepipe: DatePipe,
  ) {
    this.job_id = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.showJob()
  }

  showJob() {
    var obj = {
      id: this.job_id
    }
    this.JobService.showJob(obj).subscribe(res => {

      this.job = res['job']
      this.job.stock_item_no = (this.job.stock_item_no == 'undefined' ? '' : this.job.stock_item_no)
      this.job.created_at = this.datepipe.transform(res['job'].created_at, 'dd/MM/yyyy')
      this.job.doing_date = this.datepipe.transform(this.job.doing_date, 'dd/MM/yyyy')
      this.job.done_date = this.datepipe.transform(this.job.done_date, 'dd/MM/yyyy')
      this.job.current_status_id = res['job'].current_status.job_status_master_id
      this.job.current_status = res['job'].current_status.job_status_master.name
      this.job.job_type_name = res['job'].job_type.name
      this.job.building_name = res['job'].building.name
      this.job.building_staff = res['job'].building.staff
      this.job.building_staff_email = res['job'].building.staff_email
      this.job.building_staff_phone = res['job'].building.staff_phone
      this.job.staff_name = res['job'].staff.name
      this.job.job_stock_items.forEach(element => {
        element.amount = (element.approve_status_master_id == 2 ? element.amount_approve : element.amount)

        if (element.approve_status.id == 1) {
          element.approve_status.text_color = 'text-warning'
        }
        else if (element.approve_status.id == 2) {
          element.approve_status.text_color = 'text-success'
        }
        else if (element.approve_status.id == 3 || element.approve_status.id == 5) {
          element.approve_status.text_color = 'text-danger'
        }

      });

      setTimeout(() => {
        window.print();
      }, 200);
      window.onafterprint = function () {
        window.close();
        const el: HTMLElement = document.getElementById('divPrint');
        el.style.display = 'none';
      };

    })
  }



}
