import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { API_URL } from "./api-url";

@Injectable({
  providedIn: 'root'
})
export class JobService {

  user_data = JSON.parse(sessionStorage.getItem('user'));
  header = null
  access_token = null
  options = {}

  constructor(private httpClient: HttpClient) {
    if (this.user_data) {
      this.access_token = this.user_data.access_token;
      if (this.access_token) {
        this.header = new HttpHeaders({ 'Authorization': this.access_token })
        this.options = { headers: this.header };
      }
    }
  }

  createJob(obj) {
    return this.httpClient.post(`${API_URL.API_URL}/jobs`, obj, this.options);
  }

  commentJob(obj) {
    return this.httpClient.post(`${API_URL.API_URL}/jobs/comment`, obj, this.options);
  }

  showJob(value) {
    return this.httpClient.get(`${API_URL.API_URL}/jobs/${value.id}`, this.options);
  }

  showJobByCode(job_code) {
    return this.httpClient.get(`${API_URL.API_URL}/jobs/job_code/${job_code}`, this.options);
  }

  approveJob(value) {
    return this.httpClient.post(`${API_URL.API_URL}/jobs/approve`, value, this.options);
  }

  getJobs(filters = {}) {
    var options = this.options;
    options['params'] = new HttpParams()
    Object.keys(filters).forEach(function (key) {
      options['params'] = options['params'].append('filters[' + key + ']', filters[key]);
    });
    return this.httpClient.get(`${API_URL.API_URL}/jobs`, options);
  }
}
