import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintJobComponent } from './component/print-job/print-job.component';

const routes: Routes = [
  {
    path: "admin",
    loadChildren: "../app/component/admin/admin.module#AdminModule"
  },
  {
    path: "staff",
    loadChildren: "../app/component/staff/staff.module#StaffModule"
  },
  {
    path: "client",
    loadChildren: "../app/component/clients/clients.module#ClientsModule"
  },
  {
    path: "print_job/:id",
    component: PrintJobComponent
  },
  { path: "", redirectTo: "/client", pathMatch: "full" },
  // { path: "**", redirectTo: "/client", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
